import { request } from "./index";

// schedules
export const getSchedules = (data) => request("get", `/scheduling?page=${data.page}&size=${data.size}&sort=createdAt,desc`);

export const approveSchedule = (data) => request("post", `/scheduling/payment/approve`, data);

export const refuseSchedule = (data) => request("post", `/scheduling/payment/refuse`, data);

// type
export const createType = (data) => request("post", "/scheduling/type", data);

export const getTypes = () => request("get", "/scheduling/type");

export const editType = (data) => request("put", "/scheduling/type", data);

// item
export const createItem = (data) => request("post", "/scheduling/item", data);

export const getItems = (data) => request("get", `/scheduling/item?type.name=${data}`,);

export const editItem = (data) => request("put", "/scheduling/item", data);

// identifier
export const createIdentifier = (data) => request("post", "/scheduling/item/identifier", data);

export const getIdentifiers = () => request("get", "/scheduling/item/identifier",);


