<template>
  <v-container fluid>
    <div class="d-flex align-center mb-4">
      <v-btn class="rounded-lg mr-2" icon @click="back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <h1 class="text-h5 font-weight-bold">Gerenciar</h1>

      <v-spacer />

      <v-btn text @click="createTypeDialog = true">
        Criar Tipo

        <v-icon right> mdi-plus </v-icon>
      </v-btn>
    </div>

    <v-row>
      <v-col v-for="(item, i) in this.types" cols="4" :key="i">
        <v-card outlined>
          <v-list-item class="surface">
            <v-list-item-content>
              <v-list-item-title class="text-body-1 font-weight-bold">
                {{ item.name }}
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ item.description }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action-text>
              <v-btn
                class="rounded-lg mr-2"
                small
                icon
                @click="handleManage(item)"
              >
                <v-icon>mdi-view-list</v-icon>
              </v-btn>

              <v-btn class="rounded-lg" small icon @click="handleType(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-list-item-action-text>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <!-- dialog create type -->
    <v-dialog v-model="createTypeDialog" max-width="500" persistent>
      <CreateType v-if="createTypeDialog" @close="createTypeDialog = false" @success="getData()" />
    </v-dialog>

    <!-- dialog manage type -->
    <v-dialog v-model="manageDialog" max-width="500" persistent>
      <ManageType
        v-if="manageDialog"
        :type="selected"
        @close="manageDialog = false"
        @success="getData()"
      />
    </v-dialog>

    <!-- dialog edit type -->
    <v-dialog v-model="editDialog" max-width="500" persistent>
      <EditType
        v-if="editDialog"
        :type="selected"
        @close="editDialog = false"
        @success="getData()"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import CreateType from "@/components/schedules/CreateType";
import ManageType from "@/components/schedules/ManageType";
import EditType from "@/components/schedules/EditType";
import { getTypes } from "@/services/schedules";

export default {
  data() {
    return {
      selected: null,
      manageDialog: false,
      createTypeDialog: false,
      editDialog: false,
      types: [],
    };
  },

  components: {
    CreateType,
    ManageType,
    EditType,
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        await getTypes().then((res) => {
          this.types = res;
        });
      } catch (err) {
        console.log(err);
      }
    },

    handleManage(data) {
      this.selected = data;
      this.manageDialog = true;
    },

    handleType(data) {
      this.selected = data;
      this.editDialog = true;
    },

    back() {
      this.$router.push({ path: "/schedules" });
    },
  },
};
</script>

<style>
</style> 