<template>
  <v-card>
    <v-card-title>
      Criar identificador <v-spacer />
      <v-btn class="rounded-lg" icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <section class="px-6">
      <!-- create new -->
      <v-form v-model="valid" ref="form" autocomplete="off">
        <v-text-field
          v-model="form.name"
          label="Nome"
          background-color="accent"
          prepend-inner-icon="mdi-label-multiple-outline"
          :rules="rules"
          solo
          flat
          @keydown.space.prevent
        />
      </v-form>
    </section>

    <v-card-actions class="altback">
      <v-spacer />

      <v-btn class="mr-2" plain @click="close()"> Cancelar </v-btn>

      <v-btn color="gradient" dark @click="submit()"> Confirmar </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { createIdentifier } from "@/services/schedules";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      form: {
        name: "",
        identifiers_cascade: [],
      },
    };
  },

  computed: {
    rules() {
      return [(v) => !!v || "Campo obrigatório"];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          ...this.form,
        };

        await createIdentifier(payload).then((res) => {
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    close() {
      this.$emit("close");
    },

    displayAlert,
  },
};
</script>

<style>
</style>