<template>
  <v-card>
    <v-card-title>
      Criar Tipo

      <v-spacer />

      <v-btn class="rounded-lg" icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <section class="px-6">
      <!-- info -->
      <section>
        <span class="text-body-1 font-weight-bold"> Informações </span>

        <v-form v-model="valid" class="mt-2" ref="form" autocomplete="off">
          <v-text-field
            v-model="form.name"
            label="Nome"
            background-color="accent"
            prepend-inner-icon="mdi-label-outline"
            :rules="rules"
            solo
            flat
          />

          <v-text-field
            v-model="form.key"
            label="Identificador"
            background-color="accent"
            prepend-inner-icon="mdi-label-multiple-outline"
            :rules="rules"
            solo
            flat
          />

          <v-text-field
            v-model="form.description"
            label="Descrição"
            background-color="accent"
            prepend-inner-icon="mdi-text"
            :rules="rules"
            solo
            flat
          />
        </v-form>
      </section>

      <!-- calendar -->
      <section class="mb-6">
        <span class="text-body-1 font-weight-bold"> Disponibilidade </span>

        <v-list class="pa-0 transparent mt-2" nav>
          <v-list-item
            v-for="(item, i) in form.calendar"
            class="accent"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title
                :class="`text-body-1 ${item.isActive || 'subtext--text'}`"
              >
                {{ getLabelDay(i) }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-content class="py-0">
              <v-text-field
                v-model="item.start"
                v-mask="'##:##'"
                placeholder="HH:MM"
                class="font-weight-regular"
                background-color="accent"
                prefix="Início"
                :disabled="!item.isActive"
                :rules="rulesTime"
                hide-details
                dense
                solo
                flat
              />
            </v-list-item-content>

            <v-list-item-content class="py-0">
              <v-text-field
                v-model="item.finish"
                v-mask="'##:##'"
                placeholder="HH:MM"
                class="font-weight-regular"
                background-color="accent"
                prefix="Fim"
                :disabled="!item.isActive"
                :rules="rulesTime"
                hide-details
                dense
                solo
                flat
              />
            </v-list-item-content>

            <v-list-item-action>
              <v-checkbox
                v-model="item.isActive"
                color="primary"
                hide-details
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </section>
    </section>

    <v-card-actions class="altback">
      <v-spacer />

      <v-btn class="mr-2" plain @click="close()"> Cancelar </v-btn>

      <v-btn color="gradient" dark @click="submit()"> Confirmar </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { createType } from "@/services/schedules";
import { displayAlert } from "@/utils";
import { mask } from "vue-the-mask";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      form: {
        name: "",
        key: "",
        description: "",
        calendar: {
          sunday: {
            isActive: true,
            start: "12:00",
            finish: "23:59",
          },
          monday: {
            isActive: true,
            start: "12:00",
            finish: "23:59",
          },
          tuesday: {
            isActive: true,
            start: "12:00",
            finish: "23:59",
          },
          wednesday: {
            isActive: true,
            start: "12:00",
            finish: "23:59",
          },
          thursday: {
            isActive: true,
            start: "12:00",
            finish: "23:59",
          },
          friday: {
            isActive: true,
            start: "12:00",
            finish: "23:59",
          },
          saturday: {
            isActive: true,
            start: "12:00",
            finish: "23:59",
          },
        },
      },
    };
  },

  directives: { mask },

  computed: {
    rules() {
      return [(v) => !!v || "Campo obrigatório"];
    },

    rulesTime() {
      return [
        (v) => !!v || "Campo obrigatório",
        (v) => Number(v.slice(0, 2)) <= 24 || "Horário inválido",
        (v) => Number(v.slice(3, 5)) <= 60 || "Horário inválido",
      ];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          ...this.form,
        };

        await createType(payload).then((res) => {
          this.displayAlert("Criado com sucesso");
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    getLabelDay(value) {
      switch (value) {
        case "sunday":
          return "Domingo";
        case "monday":
          return "Segunda";
        case "tuesday":
          return "Terça";
        case "wednesday":
          return "Quarta";
        case "thursday":
          return "Quinta";
        case "friday":
          return "Sexta";
        case "saturday":
          return "Sabado";
      }
    },

    close() {
      this.$emit("close");
    },

    displayAlert,
  },
};
</script>

<style>
</style>