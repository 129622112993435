<template>
  <v-card>
    <v-card-title>
      Gerenciar tipo <v-spacer />
      <v-btn class="rounded-lg" icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <section class="mx-6">
      <div class="d-flex">
        <span class="font-weight-bold"> Itens disponíveis </span>

        <v-spacer />

        <v-btn class="rounded-lg mr-1" icon small @click="createDialog = true">
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </div>

      <!-- list -->
      <v-list
        v-if="items.length > 0"
        class="pa-0 transparent mt-2 mb-6"
        dense
        nav
      >
        <v-list-item v-for="(item, i) in items" class="altback" :key="i">
          <v-list-item-content>
            <v-list-item-title class="font-weight-regular">
              {{ item.name }}
            </v-list-item-title>

            <v-list-item-subtitle class="font-weight-regular text-caption">
              Duração: {{ item.duration_hours }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-content class="text-right">
            <v-list-item-title class="font-weight-regular">
              R$ {{ moneyMask(item.price) }}
            </v-list-item-title>

            <v-list-item-subtitle class="font-weight-regular text-caption">
              Preço
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon small @click="handleEdit(item)">mdi-pencil</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <!-- empty -->
      <div v-else class="d-flex pb-6 pt-3 justify-center">
        <span class="text-body-2 subtext--text"> Nenhum item criado </span>
      </div>
    </section>

    <v-card-actions class="altback">
      <v-spacer />

      <v-btn class="mr-2" plain @click="close()"> Cancelar </v-btn>

      <v-btn color="gradient" dark> Confirmar </v-btn>
    </v-card-actions>

    <!-- dialog create type -->
    <v-dialog v-model="createDialog" width="500" persistent>
      <CreateItem
        v-if="createDialog"
        :type="type"
        @close="createDialog = false"
        @success="getData()"
      />
    </v-dialog>

    <!-- dialog edit type -->
    <v-dialog v-model="editDialog" width="500" persistent>
      <EditItem
        v-if="editDialog"
        :item="selected"
        @close="editDialog = false"
        @success="getData()"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { getItems } from "@/services/schedules";
import { moneyMask } from "@/utils";
import CreateItem from "./CreateItem";
import EditItem from "./EditItem";

export default {
  data() {
    return {
      valid: false,
      createDialog: false,
      editDialog: false,
      valid: false,
      selected: null,
      items: [],
    };
  },

  props: {
    type: {
      type: Object,
      default: {},
    },
  },

  components: {
    CreateItem,
    EditItem,
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        await getItems(this.type.name).then((res) => {
          this.items = res;
        });
      } catch (err) {
        console.log(err);
      }
    },

    handleEdit(data) {
      this.selected = data;
      this.editDialog = true;
    },

    close() {
      this.$emit("close");
    },

    moneyMask,
  },
};
</script>

<style>
</style>