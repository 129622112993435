<template>
  <v-card>
    <v-card-title>
      Criar item <v-spacer />
      <v-btn class="rounded-lg" icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <section class="px-6">
      <v-form v-model="valid" ref="form" autocomplete="off">
        <!-- price -->
        <v-text-field
          v-model="form.name"
          label="Nome"
          background-color="accent"
          prepend-inner-icon="mdi-label-multiple-outline"
          :rules="rules"
          solo
          flat
        />

        <!-- price -->
        <v-text-field
          v-model="form.price"
          v-money="money"
          label="Preço"
          placeholder="0,00"
          background-color="accent"
          prepend-inner-icon="mdi-currency-brl"
          :rules="rulesValue"
          solo
          flat
        />

        <!-- duration -->
        <label class="font-weight-bold">Tempo de duração</label>
        <v-chip-group
          v-model="form.duration_hours"
          class="mb-4"
          mandatory
          column
        >
          <v-chip
            v-for="(item, i) in hours"
            class="mr-2"
            :value="item.value"
            :key="i"
            outlined
            filter
          >
            {{ item.label }}
          </v-chip>
        </v-chip-group>

        <v-switch
          v-model="form.one_per_day"
          class="mt-0"
          label="1 agendamento por dia"
        />

        <v-switch
          v-model="form.identifier_required"
          class="mt-0"
          label="Identificador obrigatório"
        />

        <!-- identifiers -->
        <section class="mb-6">
          <div class="d-flex">
            <label class="font-weight-bold">Selecione os identificadores</label>

            <v-spacer />

            <v-btn
              class="rounded-lg mr-1"
              icon
              small
              @click="identifierDialog = true"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </div>

          <v-chip-group v-model="form.identifiers" column multiple>
            <v-chip
              v-for="(item, i) in identifiers"
              class="mr-2"
              :value="item._id"
              :key="i"
              outlined
              filter
            >
              {{ item.name }}
            </v-chip>
          </v-chip-group>
        </section>
      </v-form>
    </section>

    <v-card-actions class="altback">
      <v-spacer />

      <v-btn class="mr-2" plain @click="close()"> Cancelar </v-btn>

      <v-btn color="gradient" dark @click="submit()"> Confirmar </v-btn>
    </v-card-actions>

    <!-- dialog manage identifier -->
    <v-dialog v-model="identifierDialog" max-width="400" persistent>
      <CreateIdentifier
        v-if="identifierDialog"
        @success="
          getSelected($event);
          getData();
        "
        @close="identifierDialog = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { createItem, getIdentifiers } from "@/services/schedules";
import { displayAlert, parseNumber } from "@/utils";
import { VMoney } from "v-money";
import CreateIdentifier from "@/components/schedules/CreateIdentifier";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      identifierDialog: false,
      money: {
        precision: 2,
        decimal: ",",
        thousands: ".",
      },
      form: {
        name: "",
        price: "0,00",
        duration_hours: 0.25,
        one_per_day: false,
        identifier_required: true,
        identifiers: [],
      },
      hours: [
        {
          label: "15 min",
          value: 0.25,
        },
        {
          label: "30 min",
          value: 0.5,
        },
        {
          label: "1 hora",
          value: 1,
        },
        {
          label: "2 horas",
          value: 2,
        },
        {
          label: "3 horas",
          value: 3,
        },
        {
          label: "4 horas",
          value: 4,
        },
        {
          label: "5 horas",
          value: 5,
        },
        {
          label: "6 horas",
          value: 6,
        },
        {
          label: "7 horas",
          value: 7,
        },
        {
          label: "8 horas",
          value: 8,
        },
        {
          label: "9 horas",
          value: 9,
        },
        {
          label: "10 horas",
          value: 10,
        },
        {
          label: "11 horas",
          value: 11,
        },
        {
          label: "12 horas",
          value: 12,
        },
      ],
      identifiers: [],
    };
  },

  props: {
    type: {
      type: Object,
      default: {},
    },
  },

  components: {
    CreateIdentifier,
  },

  directives: { money: VMoney },

  beforeMount() {
    this.getData();
  },

  computed: {
    rules() {
      return [(v) => !!v || "Campo obrigatório"];
    },

    rulesValue() {
      return [
        (v) => !!v || "Campo obrigatório",
        (v) => parseNumber(v) > 0 || "Preço deve ser superior a R$ 0,00",
      ];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          name: this.form.name,
          price: parseNumber(this.form.price),
          duration_hours: this.form.duration_hours,
          one_per_day: this.form.one_per_day,
          identifier_required: this.form.identifier_required,
          identifiers: this.form.identifiers,
          type: this.type._id,
        };

        await createItem(payload).then((res) => {
          this.displayAlert("Criado com sucesso");
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    async getData() {
      try {
        await getIdentifiers().then((res) => {
          this.identifiers = res;
        });
      } catch (err) {
        console.log(err);
      }
    },

    getSelected(data) {
      this.form.identifiers = data;
    },

    close() {
      this.$emit("close");
    },

    displayAlert,
  },
};
</script>

<style>
</style>